exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cryptomonnaie-js": () => import("./../../../src/pages/cryptomonnaie.js" /* webpackChunkName: "component---src-pages-cryptomonnaie-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscription-js": () => import("./../../../src/pages/inscription.js" /* webpackChunkName: "component---src-pages-inscription-js" */),
  "component---src-pages-investissements-js": () => import("./../../../src/pages/investissements.js" /* webpackChunkName: "component---src-pages-investissements-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-mind-7-js": () => import("./../../../src/pages/mind7.js" /* webpackChunkName: "component---src-pages-mind-7-js" */),
  "component---src-pages-politique-de-confidentialite-js": () => import("./../../../src/pages/politique-de-confidentialite.js" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-js" */),
  "component---src-pages-ressources-js": () => import("./../../../src/pages/ressources.js" /* webpackChunkName: "component---src-pages-ressources-js" */),
  "component---src-pages-temoignages-js": () => import("./../../../src/pages/temoignages.js" /* webpackChunkName: "component---src-pages-temoignages-js" */),
  "component---src-pages-trading-js": () => import("./../../../src/pages/trading.js" /* webpackChunkName: "component---src-pages-trading-js" */)
}

