import './src/styles/global.css'

// Assurer un fond noir dès le début du chargement de la page
export const onClientEntry = () => {
    // Injecter un style pour le fond noir dès que possible
    if (typeof window !== 'undefined') {
        const style = document.createElement('style')
        style.innerHTML = `
      html, body {
        background-color: #000000 !important;
      }
    `
        document.head.appendChild(style)
    }
}

// S'assurer que les transitions sont fluides entre les pages
export const shouldUpdateScroll = () => {
    return false
}

